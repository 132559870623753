import {axiosAccessToken, axiosAuth, axiosPublic} from "@/services/axiosService.ts";
import {AccessTokenResponse, IGroup, ILogin, IOrder, ISetupIntent, LoginParams, OrderParams, RegisterParams} from "@/types/type.ts";

export const getAccessToken = async (): Promise<AccessTokenResponse> => {
    const params = {
        "client_id": import.meta.env.VITE_CLIENT_ID,
        "client_secret": import.meta.env.VITE_CLIENT_SECRET,
        "grant_type": "client_credentials",
        "scope": "read-groups read-pt-sessions write-orders"
    }
    return await axiosPublic.post('/oauth/token', params)
};

export const searchGroup = async (): Promise<IGroup[]> => {
    // const {data} = await axiosAccessToken.get(`/api/groups/search?term=${import.meta.env.VITE_TERM ?? 'PureGym'}`)
    // return data
    return [
        {
            "id": 178,
            "name": "puregym_halifax",
            "display_name": "PureGym Halifax",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703014.jpg",
            "short_name": "PH",
            "google_places_id": null,
            "created_at": "2023-12-11T13:24:51.000000Z",
            "updated_at": "2023-12-11T13:24:51.000000Z",
            "deleted_at": null,
            "request_to_join": true,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 190,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 180,
            "name": "puregym_leeds_hunslet",
            "display_name": "PureGym Leeds Hunslet",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703014.jpg",
            "short_name": "LH",
            "google_places_id": null,
            "created_at": "2024-05-03T16:03:06.000000Z",
            "updated_at": "2024-05-03T16:03:06.000000Z",
            "deleted_at": null,
            "request_to_join": true,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 182,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 181,
            "name": "puregym_selby",
            "display_name": "PureGym Selby",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-791764.jpg",
            "short_name": "S",
            "google_places_id": null,
            "created_at": "2024-08-29T13:12:46.000000Z",
            "updated_at": "2024-08-29T13:12:46.000000Z",
            "deleted_at": null,
            "request_to_join": true,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 78,
            "logo_for_dark_background_url": "group-avatars/puregym_square.jpeg",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 182,
            "name": "puregym_barnsley",
            "display_name": "PureGym Barnsley",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703014.jpg",
            "short_name": "PB",
            "google_places_id": null,
            "created_at": "2023-12-11T13:24:51.000000Z",
            "updated_at": "2023-12-11T13:24:51.000000Z",
            "deleted_at": null,
            "request_to_join": true,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 190,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 183,
            "name": "puregym_eccles",
            "display_name": "PureGym Eccles",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703014.jpg",
            "short_name": "",
            "google_places_id": null,
            "created_at": "2024-11-05T12:26:02.000000Z",
            "updated_at": "2024-11-05T12:26:02.000000Z",
            "deleted_at": null,
            "request_to_join": false,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 6,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        // {
        //     "id": 184,
        //     "name": "puregym_knaresborough",
        //     "display_name": "PureGym Knaresborough",
        //     "bio": "",
        //     "avatar_url": "group-avatars/puregym_square.jpeg",
        //     "owner_id": 0,
        //     "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703012.jpg",
        //     "short_name": "",
        //     "google_places_id": null,
        //     "created_at": "2024-11-05T12:26:35.000000Z",
        //     "updated_at": "2024-11-05T12:26:35.000000Z",
        //     "deleted_at": null,
        //     "request_to_join": false,
        //     "model_type": "group",
        //     "longitude": null,
        //     "latitude": null,
        //     "type": "open",
        //     "member_count": 0,
        //     "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
        //     "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
        //     "unit_preference": "kg",
        //     "leaderboards": [],
        //     "has_joined": null,
        //     "has_sgpt": 1
        // },
        // {
        //     "id": 185,
        //     "name": "puregym_stockton-on-tees",
        //     "display_name": "PureGym Stockton-on-Tees",
        //     "bio": "",
        //     "avatar_url": "group-avatars/puregym_square.jpeg",
        //     "owner_id": 0,
        //     "cover_photo_url": "group-cover-photos/pexels-victor-freitas-685531.jpg",
        //     "short_name": "",
        //     "google_places_id": null,
        //     "created_at": "2024-11-05T12:26:59.000000Z",
        //     "updated_at": "2024-11-05T12:26:59.000000Z",
        //     "deleted_at": null,
        //     "request_to_join": false,
        //     "model_type": "group",
        //     "longitude": null,
        //     "latitude": null,
        //     "type": "open",
        //     "member_count": 0,
        //     "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
        //     "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
        //     "unit_preference": "kg",
        //     "leaderboards": [],
        //     "has_joined": null,
        //     "has_sgpt": 1
        // },
        {
            "id": 188,
            "name": "puregym_altrincham",
            "display_name": "PureGym Altrincham",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703012.jpg",
            "short_name": "PA",
            "google_places_id": null,
            "created_at": "2024-12-09T18:37:35.000000Z",
            "updated_at": "2024-12-09T18:37:35.000000Z",
            "deleted_at": null,
            "request_to_join": false,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 10,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 189,
            "name": "puregym_tyldesley",
            "display_name": "PureGym Tyldesley",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703012.jpg",
            "short_name": "PT",
            "google_places_id": null,
            "created_at": "2024-12-09T18:37:47.000000Z",
            "updated_at": "2024-12-09T18:37:47.000000Z",
            "deleted_at": null,
            "request_to_join": false,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 6,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 190,
            "name": "puregym_newcastle_eldon_gardens",
            "display_name": "PureGym Newcastle Eldon Gardens",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703014.jpg",
            "short_name": "PN",
            "google_places_id": null,
            "created_at": "2024-12-09T18:37:54.000000Z",
            "updated_at": "2024-12-09T18:37:54.000000Z",
            "deleted_at": null,
            "request_to_join": false,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 8,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 191,
            "name": "puregym_durham_arnison",
            "display_name": "PureGym Durham Arnison",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-791764.jpg",
            "short_name": "PD",
            "google_places_id": null,
            "created_at": "2024-12-09T18:38:02.000000Z",
            "updated_at": "2024-12-09T18:38:02.000000Z",
            "deleted_at": null,
            "request_to_join": false,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 7,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 194,
            "name": "puregym_washington",
            "display_name": "PureGym Washington",
            "bio": "",
            "avatar_url": "group-avatars/puregym_square.jpeg",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-1092874.jpg",
            "short_name": "",
            "google_places_id": null,
            "created_at": "2025-01-22T16:22:24.000000Z",
            "updated_at": "2025-01-22T16:22:24.000000Z",
            "deleted_at": null,
            "request_to_join": false,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 5,
            "logo_for_dark_background_url": "group-avatars/PG_transparent.png",
            "logo_for_light_background_url": "group-avatars/puregym_square.jpeg",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": null,
            "has_sgpt": 1
        },
        {
            "id": 195,
            "name": "puregym_kendal",
            "display_name": "PureGym Kendal",
            "bio": "",
            "avatar_url": "",
            "owner_id": 0,
            "cover_photo_url": "group-cover-photos/pexels-victor-freitas-703014.jpg",
            "short_name": "",
            "google_places_id": null,
            "created_at": "2025-02-12T08:31:43.000000Z",
            "updated_at": "2025-02-12T08:31:43.000000Z",
            "deleted_at": null,
            "request_to_join": true,
            "model_type": "group",
            "longitude": null,
            "latitude": null,
            "type": "open",
            "member_count": 2,
            "logo_for_dark_background_url": "",
            "logo_for_light_background_url": "",
            "unit_preference": "kg",
            "leaderboards": [],
            "has_joined": false,
            "has_sgpt": 1
        }
    ];
}

export const getGroupPTs = async (groupId: number) => {
    const {data} = await axiosAccessToken.get(`/api/group/${groupId}/pts`)
    return data
}

export const getPTSessions = async (groupId?: number, pt?: number | null) => {
    let string = '';

    if (groupId) {
        string = `pt-sessions?group=${groupId}`;
    }
    if (pt) {
        string = `pt-sessions?pt=${pt}`;
    }

    if (groupId && pt) {
        string = `pt-sessions?group=${groupId}&pt=${pt}`;
    }

    const {data} = await axiosAccessToken.get(`/api/product/${import.meta.env.VITE_PRODUCT_ID}/${string}`)
    console.log(data);
    return data
}

export const getPTSession = async (id: number) => {
    return await axiosAccessToken.get(`/api/pt-session/${id}`);
}

export const login = async (params: LoginParams): Promise<ILogin> => {
    if (!params.device_name) {
        params.device_name = "booking-app"
    }
    return await axiosPublic.post('/api/sanctum/token', params).then((response) => {
        localStorage.setItem('AuthToken', response.data.token);
        return response.data;
    });
}

export const register = async (params: RegisterParams) => {
    return await axiosPublic.post('/register', params)
}

export const requestOTP = async (email: string) => {
    return await axiosPublic.post('/api/otp/request', {email})
}

export const otpLogin = async (email: string, passcode: string) => {
    return await axiosPublic.post('/api/otp/login', {email, passcode})
        .then((response) => {
            localStorage.setItem('AuthToken', response.data.token);
            return response;
        });
}

export const createOrder = async (params: OrderParams): Promise<IOrder> => {
    const {data} = await axiosAuth.post(`/api/order`, params)
    return data
}

export const me = async () => {
    return await axiosAuth.get(`/api/me`)
}

export const homeGroup = async (id: number) => {
    return await axiosAuth.get(`/api/user/${id}/home-group`);
}

export const setHomeGroup = async (id: number, groupId: number) => {
    return await axiosAuth.put(`/api/user/${id}/home-group`, {group_id: groupId});
}

export const joinGroup = async (groupId: number) => {
    return await axiosAuth.put(`/api/groups/${groupId}/join`);
}

export const getSetUpIntent = async (): Promise<ISetupIntent> => {
    return await axiosAuth.post(`/api/stripe/save-payment-details/setup`, {payment_vendor: 'stripe', version: "2020-08-27"});
}

export const getSubscriptionPreview = async (params: OrderParams) => {
    return await axiosAuth.post(`/api/order/preview`, params);
}
